// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

// ハンバーガーメニュー
$(function() {
  const btn = $('.header-nav-toggle-btn');
  const nav = $('.header-nav');
  btn.on('click', function() {
    $(this).toggleClass('active');
    nav.toggleClass('show');
  });
  $('.header-nav-list a').on('click', function() {
    btn.removeClass('active');
    nav.removeClass('show');
  });
});
